import { IStepButton, StepActionTypes, StepsIconTypes } from '@workerbase/domain/workinstruction';
import { generateButtonStylesForIconType } from '@workerbase/types/WorkinstructionStep/WorkinstructionStep';
import { WiWorkflowType } from '../types';

export const getDefaultStepButtons = (workflowType?: WiWorkflowType): IStepButton[] => {
  if (workflowType === WiWorkflowType.SECONDARY) {
    return [
      {
        text: 'Close',
        index: 0,
        action: { type: StepActionTypes.CLOSE },
        icon: StepsIconTypes.CLOSE,
        styles: generateButtonStylesForIconType(StepsIconTypes.CLOSE),
      },
      {
        text: 'Finish',
        index: 1,
        action: { type: StepActionTypes.FINISH },
        icon: StepsIconTypes.CONFIRM,
        styles: generateButtonStylesForIconType(StepsIconTypes.CONFIRM),
      },
    ];
  }
  return [
    {
      text: 'Suspend',
      index: 0,
      action: { type: StepActionTypes.SUSPEND },
      icon: StepsIconTypes.CLOSE,
      styles: generateButtonStylesForIconType(StepsIconTypes.CLOSE),
    },
    {
      text: 'Finish',
      index: 1,
      action: { type: StepActionTypes.FINISH },
      icon: StepsIconTypes.CONFIRM,
      styles: generateButtonStylesForIconType(StepsIconTypes.CONFIRM),
    },
  ];
};
