import { SortOrder, IFilter, Languages } from '@workerbase/domain/common';
import { IPaginatedQueryParams } from '@workerbase/api/http/common';
import { PaginatedResponse } from '@workerbase/types/api/pagination';
import { ListConfigSorting } from '@workerbase/api/http/user';
import { ITranslation } from '@workerbase/domain/translation';
import { TS } from '@workerbase/utils/TS';
import { NodeRedFlowGET, SettingsGET } from 'services/types/Settings';
import { ReSyncBody } from '@workerbase/types/api/settings';
import { ConditionOperator } from '@workerbase/domain/rule';
import { excludeInvalidConditions } from '@workerbase/utils/Filter';
import { AvailableCondition } from '@workerbase/types/RuleCondition/AvailableCondition';
import { ApiResponse } from '@workerbase/types/Response';
import { api } from './api';

const SETTINGS_ENDPOINT = '/api/v1/settings';

export const getSettings = async (): Promise<SettingsGET> => {
  const { data } = await api.get<SettingsGET>(SETTINGS_ENDPOINT);

  return data;
};

export const getLanguages = async (): Promise<string[]> => {
  const {
    data: { data },
  } = await api.get<{ data: string[] }>(`${SETTINGS_ENDPOINT}/languages`);

  return data;
};

export const updateLanguages = async (languages: string): Promise<Languages[]> => {
  const {
    data: { data },
  } = await api.put<{ data: Languages[] }>(`${SETTINGS_ENDPOINT}/languages`, languages);
  return data;
};

export const triggerElasticSync = async (body: ReSyncBody): Promise<ReSyncBody> => {
  const { data } = await api.post<ReSyncBody>(`${SETTINGS_ENDPOINT}/re-sync`, body);
  return data;
};

export const getNodeRedFlows = async (): Promise<NodeRedFlowGET[]> => {
  const {
    data: { data },
  } = await api.get<{ data: NodeRedFlowGET[] }>(`${SETTINGS_ENDPOINT}/nodeRedFlows`);

  return data;
};

type GetTranslationsPaginated = (props: {
  page: number;
  perPage: number;
  sorting?: ListConfigSorting;
  filtering?: string;
  filter?: IFilter<ConditionOperator>;
}) => Promise<PaginatedResponse<ITranslation[]>>;

export const getTranslationsPaginated: GetTranslationsPaginated = async ({
  page = 1,
  perPage = 20,
  sorting,
  filtering,
  filter,
}) => {
  const params: IPaginatedQueryParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector ?? TS.property<ITranslation>()('value').key,
    order: sorting?.sortDirection ?? SortOrder.ASC,
    textSearch: filtering,
  };

  const body = filter ? excludeInvalidConditions(filter) : undefined;

  const { data } = await api.post<PaginatedResponse<ITranslation[]>>(`${SETTINGS_ENDPOINT}/translations`, body, {
    params,
  });

  return data;
};

// TODO: set return type
export const updateTranslations = async (translations: ITranslation[]): Promise<unknown> => {
  const { data } = await api.put<unknown>(`${SETTINGS_ENDPOINT}/translations/batch`, translations);

  return data;
};

type GetTranslationsFilterConditions = () => Promise<GetTranslationsFilterConditionsResponse>;
type GetTranslationsFilterConditionsResponse = AvailableCondition[];

export const getTranslationsFilterConditions: GetTranslationsFilterConditions = async () => {
  const {
    data: { data: conditions },
  } = await api.get<ApiResponse<GetTranslationsFilterConditionsResponse>>(
    `${SETTINGS_ENDPOINT}/translations/conditions`,
  );

  return conditions;
};
